import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/shared/services/users.service';
declare var $: any;

@Component({
  selector: 'app-mng-users',
  templateUrl: './mng-users.component.html',
  styleUrls: ['./mng-users.component.css']
})
export class MngUsersComponent implements OnInit {

  users: any;
  roles: any;

  selectedUser: any;

  addForm: FormGroup;
  editForm: FormGroup;

  config: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  constructor(
    private toast : ToastrService,
    private userService : UsersService,
    private fb : FormBuilder
  ) { }

  ngOnInit(): void {
    this.getUsers();

    this.addForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      username: ['', Validators.required],
      roleId: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.getRoles();

    this.editForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      username: ['', Validators.required],
      roleId: ['', Validators.required]
    });
  }

  handlePageChange(event) {
    this.page = event;
    this.getUsers();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getUsers();
  }

  getRoles(){
    this.userService.getAllRoles().subscribe(
      data => {
        // console.table(data);

        this.roles = data;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permission to accees this Resource");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  getUsers(){
    this.userService.getPaginatedUsers(this.page - 1, this.pageSize).subscribe(
      data => {
        // console.table(data);        
        this.config = {
          itemsPerPage: this.pageSize,
          currentPage: data.pageable.pageNumber + 1,
          totalItems: data.totalElements,
          totalPages: data.totalPages
        };   

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }

        this.users = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permission to accees this Resource");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  createUser(form: FormGroup){
    // console.log(form.value);

    if(!this.validatePasswords(form.value.password)){
      this.toast.error("Weak Password. (8 Characters with 1 Capital, 1 Number and 1 Symbol)");
      return false;
    }

    if(form.valid){
      this.userService.createUser(form.value).subscribe(
        resp => {
          // console.log(resp);

          $('#add').modal('hide');
          this.getUsers();
          this.toast.success('User Created Succesfully ');
          this.addForm.reset;
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning("Missing Required Info");
    }
    
  }

  updateUser(form: FormGroup){
    // console.log(form.value);
    
    if(form.valid){
      this.userService.updateUser(form.value).subscribe(
        resp => {
          // console.log(resp);

          $('#edit').modal('hide');
          this.getUsers();
          this.toast.success('User Updated Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning("Missing Required Info");
    }
  }

  validatePasswords(pwd){
    const rd = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    return rd.test(pwd);
  }

  loadUser(ref){
    let user;
    
    if(ref){
      this.users.forEach(r => {
        if(r.id == ref) { user = r };
       });

      //  console.log(user);
       
       if(user){
        this.editForm = this.fb.group({
          id: [user.id, Validators.required],
          email: [user.email, Validators.required],
          firstName: [user.firstname, Validators.required],
          lastName: [user.lastname, Validators.required],
          username: [user.username, Validators.required],
          roleId: [user.roles[0].id, Validators.required],
        });

        $('#edit').modal('show');
       }
    }
    else{
      this.toast.warning("Reference not Found.");
    }
  }

  public removeUnderscore(str: string) {
    return str.replace(/_/g, " ");
  }

  selectUser(ref){
    if(ref){
      this.selectedUser = ref;
      $('#delete').modal('show');
    }
  }

  deleteUser(){
    this.selectedUser ?
    this.userService.deleteUser(this.selectedUser).subscribe(
      resp => {
        console.log(resp);
        this.toast.success('User Deleted Successfully');
        $('#delete').modal('hide');
        this.getUsers();
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
          else if(err.error?.message){
          this.toast.error(err.error.message);
        } else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permission to accees this Resource");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    ) :
    this.toast.warning('No Reference found.');

  }

  activateUser(ref, status){
    ref ?
    !status ? 
    this.userService.deactivateUser(ref).subscribe(
      resp => {
        // console.log(resp);
        this.toast.success('User Deactivated Successfully');
        this.getUsers();
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
          else if(err.error?.message){
          this.toast.error(err.error.message);
        } else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permission to accees this Resource");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    ) : 
    this.userService.activateUser(ref).subscribe(
      resp => {
        // console.log(resp);
        this.toast.success('User Activated Successfully');
        this.getUsers();
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
          else if(err.error?.message){
          this.toast.error(err.error.message);
        } else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permission to accees this Resource");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    ) :
    this.toast.warning('No Reference found.');

  }

}
