import { Router } from '@angular/router';
import { AccountsService } from './../../services/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-self-company',
  templateUrl: './self-company.component.html',
  styleUrls: ['./self-company.component.css']
})
export class SelfCompanyComponent implements OnInit {

  addForm: FormGroup;
  mngrForm: FormGroup;
  mobErr: string;
  mobErr2: string;

  companyId: number;
  mode: string = 'account';

  agreed: boolean = true;

  constructor(
    private toast: ToastrService,
    private accountsService: AccountsService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.addForm = this.fb.group({
      companyName: ['', Validators.required],
      email: ['', Validators.required],
      registrationNumber: ['', Validators.required],
      contactNumber: ['', Validators.required],
      organisationId: [1, Validators.required],
    });

    this.formInit();
  }

  formInit(){
    this.mngrForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      role: ['', Validators.required],
      companyId: [this.companyId, Validators.required],
    });
  }

  selfRegister(form: FormGroup){
    // console.log(form.value);
    if(!this.validateNumber(form.value.contactNumber)){
      this.mobErr = 'Invalid Mobile Pattern';
      return false;
    }
    else{
      this.mobErr = null;
    }

    if(form.valid){
      this.accountsService.companySelfReg(form.value).subscribe(
        data => {
          // console.table(data);
          this.toast.success('Account Applied.');
          // this.router.navigate(['/login']);
          this.companyId = data.id;
          this.formInit();
          this.mode = 'manager';
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      )
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  addManager(form: FormGroup){
    // console.log(form.value);
    if(!this.validateNumber(form.value.mobile)){
      this.mobErr2 = 'Invalid Mobile Pattern';
      return false;
    }
    else{
      this.mobErr2 = null;
    }

    if(form.valid){
      this.accountsService.createSelfManager(form.value).subscribe(
        data => {
          // console.table(data);
          this.toast.success('Manager Account Created.');
          // this.router.navigate(['/login']);
          $('#verify').modal('show');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  navigateToLogin(){
    this.toast.success('Application Sent Succesfully.');
    this.router.navigate(['/login']);
  }

  aggreeToTerms(){
    let val = $('#agree').is(':checked');
    
    // val ? $('#btnSubmit').attr('disabled', false) : $('#btnSubmit').attr('disabled', false);

    this.agreed = !val;
    
  }

  validateNumber(number) {
    const re = /^2637[13478][0-9]{7}$/;
    // const re = /^(263)7[7-8|1|3][0-9]{7}$/;
    return re.test(number);
  }

}
