import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransactionsService } from 'src/app/shared/services/transactions.service';
import * as fileSaver from 'file-saver';
declare var $: any;

@Component({
  selector: 'app-account-transactions',
  templateUrl: './account-transactions.component.html',
  styleUrls: ['./account-transactions.component.css']
})
export class AccountTransactionsComponent implements OnInit {

  
  mode: string;
  modeName: string;
  ref: any;

  transactions: any;
  payload: any;

  config: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  constructor(
    private toast: ToastrService,
    private storageService: StorageService,
    private transactionsService: TransactionsService,
    private activeRoute: ActivatedRoute,
    private datePipe: DatePipe
  ) { 
    let ttyp = storageService.decrypt(sessionStorage.getItem('trans-mode'));
    let tdsp = storageService.decrypt(sessionStorage.getItem('trans-disp'));

    if(ttyp && tdsp){ 
      this.mode = ttyp;
      this.modeName = tdsp;
    }
    else{
      toast.warning('Failed to Determine Modes.');
    }
  }

  ngOnInit(): void {
    // get reference
    this.activeRoute.params.subscribe((params) => {
      this.ref = params["id"];
    });

    // create payload
    this.payload = (this.mode == 'account') ?
                      {
                        accountNumber: this.ref,
                        startDate: "2022-06-01",
                        endDate: "2024-12-31",
                        page: 0,
                        size: 100
                      } : 
                    (this.mode == 'card') ?
                      {
                        pan: this.ref,
                        startDate: "2022-06-01",
                        endDate: "2024-12-31",
                        page: 0,
                        size: 100
                      } : 
                    null;

    // get transactions
    // this.getTransactions();

  }

  handlePageChange(event) {
    this.page = event;
    this.getTransactions();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getTransactions();
  }

  getTransactions(){
    this.payload.page = this.page - 1;
    this.payload.size = this.pageSize;

    this.transactionsService.handleAccountsTransactionRequest(this.mode, this.payload).subscribe(
      data => {
        // console.log(data);
        this.config = {
          itemsPerPage: this.pageSize,
          currentPage: data.pageable.pageNumber + 1,
          totalItems: data.totalElements,
          totalPages: data.totalPages
        };   

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }
        
        this.transactions = data.content;
      },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
    );
    
  }

  generateFile(typ){
    if(!typ){
      this.toast.warning('Choose file type.');
      return false;
    }

    let d1 = $('#startDate').val();
    let d2 = $('#endDate').val();

    if(!d1){
      $('#startDate').addClass('invalid');
      return false;
    }
    else{
      $('#startDate').removeClass('invalid');
    }

    if(!d2){
      $('#endDate').addClass('invalid');
      return false;
    }
    else{
      $('#endDate').removeClass('invalid');
    }

    let info = {
      ref: this.ref,
      org: 'FUDZO',
      startDate: this.datePipe.transform(d1, 'dd/MM/yyyy'),
      endDate: this.datePipe.transform(d2, 'dd/MM/yyyy'),
      typ: typ
    };

    this.transactionsService.exportAccountsTransactionRequest(this.mode, info).subscribe(
      resp => {
        fileSaver.saveAs(resp, `${this.mode} ${this.ref} transactions ${d1} - ${d2}.${typ.toLowerCase()}`);
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 500){
          this.toast.error('Internal Server Error')
        }
        else{
          this.toast.error('Request Failed: ' + err.status);
        }
      }
    );

  }

  handleDateSelection(){
    let d1 = $('#startDate').val();
    let d2 = $('#endDate').val();

    if(!d1){
      $('#startDate').addClass('invalid');
      return false;
    }
    else{
      $('#startDate').removeClass('invalid');
    }

    if(!d2){
      $('#endDate').addClass('invalid');
      return false;
    }
    else{
      $('#endDate').removeClass('invalid');
    }

    this.payload.startDate = d1;
    this.payload.endDate = d2;

    this.getTransactions();

  }

}
