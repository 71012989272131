import { AuthService } from './../../shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  authForm: FormGroup;
  returnUrl: string;

  constructor(
    private toast : ToastrService,
    private authService : AuthService,
    private router : Router,
    private fb : FormBuilder,
    private storageService : StorageService,
    private activeRoute : ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.activeRoute.queryParams.subscribe(
      params => this.returnUrl = params['returnUrl']
    );

    this.checkUser();

    this.authForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  userLogin(form: FormGroup){
    // console.log(form.value);

    if(form.valid){
      this.authService.generalSignIn(form.value).subscribe(
        resp => {
          // console.log(resp);
          // check user role
          let role = resp.roles[0].name;
          if(role == 'ROLE_ATTENDANT' || role == 'ROLE_SUPERVISOR'){
            this.toast.warning('User role not allowed: ' + this.removeUnderscore(role));
            return false;
          }

          // check if org is correct
          let org = resp.organisation ?? null;
          if(org != 'FUDZO'){
            this.toast.warning('Wrong Organisation Platform for ' + org);
            return false;
          }

          let uType = (!resp.accountNumber || !resp.accountType) ? 'admin' : 'client';

          // store session variables
          sessionStorage.setItem('access_token', this.storageService.encrypt(resp.access_token));
          sessionStorage.setItem('user_type', this.storageService.encrypt(uType));
          sessionStorage.setItem('user', this.storageService.encrypt(form.value.username));
          sessionStorage.setItem('display', this.storageService.encrypt(resp.firstName + ' ' + resp.lastName));

          if(uType == 'client'){            
            sessionStorage.setItem('acc_number', this.storageService.encrypt(resp.accountNumber));
            sessionStorage.setItem('acc_type', this.storageService.encrypt(resp.accountType ?? 0));
          }

          this.toast.success("Login successfull");
          // this.router.navigateByUrl(this.returnUrl);
          window.location.href = this.returnUrl ? this.returnUrl : `/${uType}`;
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Invalid Credentials');
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning("Enter all Credentials.");
    }
    
  }

  checkUser(){
    if(this.authService.isLoggedIn){
      let uType = this.authService.getUserType();
      window.location.href = this.returnUrl ? this.returnUrl : `/${uType}`;
    }
  }

  public removeUnderscore(str: string) {
    return str.replace(/_/g, " ");
  }

}
