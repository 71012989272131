<div *ngIf="mode == 'account'">
  <div class="az-signup-header">
      <h2>Company Account</h2>
      <h4>It's free to signup and only takes a minute.</h4>
  
      <form [formGroup]="addForm" (ngSubmit)="selfRegister(addForm)">
        <div class="form-group">
          <label>Company Name</label>
          <input formControlName="companyName" required type="text" class="form-control" placeholder="Enter name">
        </div><!-- form-group -->
        <div class="form-group">
          <label>Registration Number</label>
          <input formControlName="registrationNumber" required type="text" class="form-control" placeholder="Enter registration">
        </div><!-- form-group -->
        <div class="form-group">
          <label>Email</label>
          <input formControlName="email" required type="text" class="form-control" placeholder="Enter email">
        </div><!-- form-group -->
        <div class="form-group">
          <label>Contact Number</label> &nbsp;&nbsp; <em class="text-muted font-smaller">263xxxxxxxxx</em> &nbsp;&nbsp; <em class="text-danger font-smaller" *ngIf="mobErr">{{mobErr}}</em>
          <input formControlName="contactNumber" required type="text" class="form-control" placeholder="Enter contact number">
        </div><!-- form-group -->
        <!-- <div class="form-group">
          <label>Organisation</label>
          <select formControlName="organisationId" required class="form-control">
            <option value="" selected disabled>choose...</option>
            <option value="1">Fudzo</option>
            <option value="2">Thuli</option>
          </select>
        </div> -->
        <div class="form-group mt-4 mb-4">
          <label class="ckbox">
            <input type="checkbox" id="agree" (change)="aggreeToTerms()"><span>I agree to the <a class="pointer text-primary" data-target="#terms" data-toggle="modal">Terms & Conditions</a>.</span>
          </label>
        </div>
        <div class="form-group"></div><!-- form-group -->
        <button type="submit" id="btnSubmit" [disabled]="agreed" class="btn btn-az-primary btn-block">Create Account</button>
        <div class="row row-xs"></div><!-- row -->
      </form>
    </div><!-- az-signup-header -->
    <div class="az-signup-footer">
      <p>Already have an account? <a routerLink="/login">Sign In</a></p>
    </div>
</div>

<div *ngIf="mode == 'manager'">
  <div class="az-signup-header">
      <h2>Account Manager</h2>
      <h4>Add users that will manage account.</h4>
  
      <form [formGroup]="mngrForm" (ngSubmit)="addManager(mngrForm)">
          <div class="form-group">
            <label>Firstname</label>
            <input formControlName="firstName" required type="text" class="form-control" placeholder="Enter your firstname">
          </div><!-- form-group -->
          <div class="form-group">
            <label>Lastname</label>
            <input formControlName="lastName" required type="text" class="form-control" placeholder="Enter your lastname">
          </div><!-- form-group -->
          <div class="form-group">
            <label>Email</label>
            <input formControlName="email" required type="text" class="form-control" placeholder="Enter your email">
          </div><!-- form-group -->
          <div class="form-group">
            <label>Mobile</label> &nbsp;&nbsp; <em class="text-muted font-smaller">263xxxxxxxxx</em> &nbsp;&nbsp; <em class="text-danger font-smaller" *ngIf="mobErr2">{{mobErr2}}</em>
            <input formControlName="mobile" required type="text" class="form-control" placeholder="Enter your mobile">
          </div><!-- form-group -->
          <div class="form-group">
            <label>Role</label>
            <select formControlName="role" required class="form-control">
              <option value="" selected disabled>choose...</option>
              <option value="MAKER">Maker</option>
              <option value="CHECKER">Checker</option>
              <option value="MAKER_CHECKER">Maker & Checker</option>
            </select>
          </div><!-- form-group -->
          <div class="form-group"></div><!-- form-group -->
          <button type="submit" class="btn btn-az-primary btn-block">Create User</button>
          <div class="row row-xs"></div><!-- row -->
        </form>
    </div><!-- az-signup-header -->
    <div class="az-signup-footer">
      <!-- <p>Already have an account? <a routerLink="/login">Sign In</a></p> -->
    </div>
</div>

<div class="modal fade" id="verify" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">CONTINUE ADDING</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2>Do you want to add another Account Manager?</h2>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-primary" data-dismiss="modal" (click)="navigateToLogin()" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-success" data-dismiss="modal" (click)="formInit()" type="button"><i class="fa fa-check"></i>&nbsp; Continue</button>
            </div>
          </div>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="terms" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">Terms & Conditions</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <div>
            <div class="container">
              <div class="row">
                <div class="col-md-12" style="height: 65vh;">
                  <object
                    data="/assets/lib/terms/Terms_and_Conditions.pdf"
                    type="application/pdf"
                    width="100%"
                    height="100%">
                    <p>Your browser does not support document preview.
                      <a href="/assets/lib/terms/Terms_and_Conditions.pdf" target="_blank">Download the Document</a>
                    </p>
                  </object>
                </div>
              </div>
            </div>
            <br><br>
          </div>
        </section>
      </div>  
      <div class="modal-footer" align="right">
        <button class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
      </div>    
    </div>
  </div>
</div>