import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  baseURL: string = environment.baseURL + '/fudzo-main-service/api/v1';

  constructor(
    private http : HttpClient
  ) { }

  // account info
  getAccountInfo(typ, ref): Observable<any> {
    return typ == 'INDIVIDUAL' ?
     this.http.get(`${this.baseURL}/individual/accountNumber?accountNumber=${ref}`) :
     this.http.get(`${this.baseURL}/company/accountNumber?accountNumber=${ref}`);

  }

  // account cards
  getAccountCards(typ, ref): Observable<any> {
    return typ == 'INDIVIDUAL' ?
     this.http.get(`${this.baseURL}/individual/account-number?accountNumber=${ref}`) :
     this.http.get(`${this.baseURL}/company/account-number?accountNumber=${ref}`);

  }

  // account search
  searchAccounts(typ, ref, owner, mode): Observable<any> {
    if(mode == 'name'){
      return typ == 'INDIVIDUAL' ?
        this.http.get(`${this.baseURL}/individual/search?name=${ref}&ownerId=${owner}`) :
        this.http.get(`${this.baseURL}/company/search?name=${ref}&ownerId=${owner}`);
    }
    else if(mode == 'card'){
      return typ == 'INDIVIDUAL' ?
        this.http.get(`${this.baseURL}/individual/search-by-card?pan=${ref}&ownerId=${owner}`) :
        this.http.get(`${this.baseURL}/company/search-by-card?pan=${ref}&ownerId=${owner}`);
    }

  }

  // Mng Individual Accounts
  getIndividualByAccNumber(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/individual/${ref}`);
  }

  getIndividualAccCards(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/individual/account-number?accountNumber=${ref}`);
  }

  getPaginatedIndividuals(page, size): Observable<any> {
    return this.http.get(`${this.baseURL}/individual/organisation/${page}/${size}?organisationId=1`);
  }

  createIndividual(data): Observable<any> {
    return this.http.post(`${this.baseURL}/individual`, data);
  }

  updateIndividual(data): Observable<any> {
    return this.http.put(`${this.baseURL}/individual`, data);
  }
  
  deleteIndividual(ref): Observable<any> {
    return this.http.delete(`${this.baseURL}/individual/${ref}`);
  }

  individualSelfReg(data): Observable<any> {
    return this.http.post(`${this.baseURL}/individual/self`, data);
  }

  addIndividualCard(data): Observable<any> {
    return this.http.put(`${this.baseURL}/individual/card`, data);
  }

  removeIndividualCard(data): Observable<any> {
    return this.http.put(`${this.baseURL}/individual/remove-card`, data);
  }

  // Mng Company Accounts
  getCompanyByAccNumber(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/company/accountNumber?accountNumber=${ref}`);
  }

  getCompanyAccCards(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/company/account-number?accountNumber=${ref}`);
  }

  getPaginatedCompany(page, size): Observable<any> {
    return this.http.get(`${this.baseURL}/company/organisation/${page}/${size}?organisationId=1`);
  }

  createCompany(data): Observable<any> {
    return this.http.post(`${this.baseURL}/company`, data);
  }

  updateCompany(data): Observable<any> {
    return this.http.put(`${this.baseURL}/company`, data);
  }
  
  deleteCompany(ref): Observable<any> {
    return this.http.delete(`${this.baseURL}/company/${ref}`);
  }

  companySelfReg(data): Observable<any> {
    return this.http.post(`${this.baseURL}/company/self`, data);
  }

  addCompanyCard(data): Observable<any> {
    return this.http.put(`${this.baseURL}/company/card`, data);
  }

  removeCompanyCard(data): Observable<any> {
    return this.http.put(`${this.baseURL}/company/remove-card`, data);
  }

  // Mng Card
  getCardId(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/card/${ref}`);
  }

  getPaginatedCards(page, size): Observable<any> {
    return this.http.get(`${this.baseURL}/card/organisation/${page}/${size}?organisation=1`);
  }

  createCard(data): Observable<any> {
    return this.http.post(`${this.baseURL}/card`, data);
  }

  updateCard(data): Observable<any> {
    return this.http.put(`${this.baseURL}/card`, data);
  }
  
  deleteCard(ref): Observable<any> {
    return this.http.delete(`${this.baseURL}/card/${ref}`);
  }

  getCardCars(pan): Observable<any> {
    return this.http.get(`${this.baseURL}/card/cars/${pan}`);
  }

  getAvailableCards(org, page, size): Observable<any> {
    return this.http.get(`${this.baseURL}/card/available/${page}/${size}/?organisation=${org}`);
  }

  blockCard(pan): Observable<any> {
    return this.http.put(`${this.baseURL}/card/block?pan=${pan}`, null)
  }

  unBlockCard(pan): Observable<any> {
    return this.http.put(`${this.baseURL}/card/activate-card/${pan}`, null)
  }

  removeCardPin(card): Observable<any> {
    return this.http.put(`${this.baseURL}/card/remove-pin?cardId=${card}`, null)
  }

  addCardOwner(data): Observable<any> {
    return this.http.put(`${this.baseURL}/card/add-owner/{pan}`, data);
  }

  // Mng Cars
  getCarId(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/car/${ref}`);
  }

  getPaginatedCars(page, size): Observable<any> {
    return this.http.get(`${this.baseURL}/car/${page}/${size}`);
  }

  createCar(data): Observable<any> {
    return this.http.post(`${this.baseURL}/car`, data);
  }

  updateCar(data): Observable<any> {
    return this.http.put(`${this.baseURL}/car/edit?registrationNumber=${data.registrationNumber}`, data);
  }
  
  deleteCar(ref): Observable<any> {
    return this.http.delete(`${this.baseURL}/car/${ref}`);
  }

  // Acc Managers
  getManagerId(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/account-manager/${ref}`);
  }

  getPaginatedManagers(page, size, org): Observable<any> {
    return org ?
    this.http.get(`${this.baseURL}/account-manager/organisation/${org}/${page}/${size}`) :
    this.http.get(`${this.baseURL}/account-manager/${page}/${size}`);
  }

  getPaginatedCompanyManagers(page, size, comp): Observable<any>{
    return this.http.get(`${this.baseURL}/account-manager/company/${comp}/${page}/${size}`);
  }

  createManager(data): Observable<any> {
    return this.http.post(`${this.baseURL}/account-manager`, data);
  }

  createSelfManager(data): Observable<any> {
    return this.http.post(`${this.baseURL}/account-manager/self/manager`, data);
  }

  updateManager(data): Observable<any> {
    return this.http.put(`${this.baseURL}/account-manager/edit?accountManager-id=${data.id}`, data);
  }
  
  deleteManager(ref): Observable<any> {
    return this.http.delete(`${this.baseURL}/account-manager/${ref}`);
  }

  // manage acc dashboard
  getDashIndividualAccounts(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/dashboard/individual-accounts?ownerId=${ref}`);
  }

  getDashCompanyAccounts(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/dashboard/company-accounts?ownerId=${ref}`);
  }

  getDashCardsStats(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/dashboard/cards-stats?ownerId=${ref}`);
  }

  getDashCardsStatsIndividual(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/dashboard/cards-stats-individual?accountId=${ref}`);
  }

  getDashCardsStatsCompany(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/dashboard/cards-stats-company?accountId=${ref}`);
  }

  getDashStationsCount(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/dashboard/servicestation?ownerId=${ref}`);
  }

  getDashTerminalCount(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/dashboard/terminal?ownerId=${ref}`);
  }

  getDashAttendantsCount(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/dashboard/attendant?ownerId=${ref}`);
  }

  getClientDashStats(ref, typ): Observable<any> {
    let resp = typ == 'COMPANY' ?
                this.getDashCardsStatsCompany(ref) : this.getDashCardsStatsIndividual(ref);

    return resp;
  }
  

}
