import { StorageService } from './../../../shared/services/storage.service';
import { OwnerService } from './../../../shared/services/owner.service';
import { StationService } from './../../../shared/services/station.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-mng-stations',
  templateUrl: './mng-stations.component.html',
  styleUrls: ['./mng-stations.component.css']
})
export class MngStationsComponent implements OnInit {

  stations: any;
  owners: any;
  selectedStation: any;
  stationId: number;

  addForm: FormGroup;
  editForm: FormGroup;

  constructor(
    private toast : ToastrService,
    private fb : FormBuilder,
    private stationService : StationService,
    private router : Router,
    private ownerService : OwnerService,
    private storageService : StorageService
  ) { }

  ngOnInit(): void {
    this.getStations();

    this.addForm = this.fb.group({
      stationName: ['', Validators.required],
      address: ['', Validators.required],
      contactNumber: ['', Validators.required],
      organisationId: ['1', Validators.required],
    });

    this.editForm = this.fb.group({
      id : 0,
      stationName: '',
      address: '',
      organisationId: '',
      contactNumber: ''
    });

    this.getCompanies();
  }
  
  getCompanies(){
    this.ownerService.getPaginatedOwners(0, 100).subscribe(
      data => {
        // console.table(data);

        this.owners = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permission to accees this Resource");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  getStations(){
    this.stationService.getPaginatedStations(0, 100).subscribe(
      data => {
        // console.table(data);

        this.stations = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permission to accees this Resource");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  addStation(form: FormGroup){
    // console.log(form.value);
    if(form.valid){
      this.stationService.createStation(form.value).subscribe(
        resp => {
          // console.log(resp);
          $('#add').modal('hide');
          this.getStations();
          this.toast.success('Station Created Succesfully ');
          this.addForm = this.fb.group({
            stationName: ['', Validators.required],
            address: ['', Validators.required],
            contactNumber: ['', Validators.required],
            organisationId: ['1', Validators.required],
          });
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

  selectStation(ref){
    ref ? this.stationId = ref : false; $('#delete').modal('show');
  }

  deleteStation(){
    if(this.stationId){
      this.stationService.deleteStation(this.stationId).subscribe(
        resp => {
          // console.log(resp);
          $('#delete').modal('hide');
          this.getStations();
          this.toast.success('Station Deleted Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

  updateStation(form: FormGroup){
    // console.log(form.value);
    if(form.valid){
      this.stationService.updateStation(form.value).subscribe(
        resp => {
          // console.log(resp);
          $('#edit').modal('hide');
          this.getStations();
          this.toast.success('Station Updated Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }    
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

  loadStation(ref){
    if(ref){
      this.stations.forEach(r => {
        (r.id == ref) ? this.selectedStation = r : '';
      });
      
      if(this.selectedStation){
        this.editForm = this.fb.group({
          serviceStationId: [1],
          stationName: [this.selectedStation.stationName, Validators.required],
          address: [this.selectedStation.address, Validators.required],
          contactNumber: [this.selectedStation.contactNumber, Validators.required],
          organisationId: [this.selectedStation.owner.id, Validators.required],
        });

        $('#edit').modal('show');
      }
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

  navigateToPage(route){
    if(route){
      // sessionStorage.setItem('station', this.storageService.encrypt(station));
      this.router.navigate([route]);
    }
  }

  viewTransactions(ref, stationName){
    sessionStorage.setItem('trans-mode', this.storageService.encrypt('station'));
    sessionStorage.setItem('trans-disp', this.storageService.encrypt(stationName));

    this.router.navigate(['/admin/stations/view-transactions/' + ref]);
  }

}
