<div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
        <app-sidebar-accounts></app-sidebar-accounts>
        <!-- az-content-left -->
      
      <div class="az-content-body pd-lg-l-40 d-flex flex-column">
        <div class="az-content-breadcrumb">
          <span>Accounts</span>
          <span>Cards</span>
        </div>
        <h2 class="az-content-title">Account Cards</h2>

        <div class="row">
          <div class="col-md-8">
            <div class="az-content-label mg-b-5">Account Cards List</div>
            <p class="mg-b-20">List of all Account Cards.</p>
          </div>
          <div class="col-md-4 mt-md-0 mt-3" align="right">
            <button class="btn btn-outline-success btn-rounded btn-sm" data-toggle="modal" data-target="#add">
              Link &nbsp;<i class="fa fa-link"></i>
            </button>
          </div>
        </div>

        <div class="table-responsive mt-4">
          <table class="table table-hover mg-b-0">
            <thead>
              <tr>
                <th>ID</th>
                <th>Fullname</th>
                <th>PAN</th>
                <th>Balance</th>
                <th>Active</th>
                <th>Blocked</th>
                <th>Vehicles</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let c of myCards;">
                <td>{{c.id}}</td>
                <td>
                  <span *ngIf="c.lastname != null">{{c.firstname}} {{c.lastname}}</span>
                  <span *ngIf="c.lastname == null" (click)="selectCard2(c.pan)" class="pointer text-primary ml-2" title="Add Card Details">
                    <i class="fa fa-plus-square"></i>
                  </span>
                  <span *ngIf="c.lastname != null" (click)="selectCard3(c.pan)" class="pointer text-success ml-2" title="Edit Card Details">
                    <i class="fa fa-edit"></i>
                  </span>
                </td>
                <td>{{c.pan}}</td>
                <td>{{c.balance | currency: 'USD' : 'symbol' : '1.2-2'}}</td>
                <td>
                    <span *ngIf="c.isActive" class="badge badge-success">active</span>
                    <span *ngIf="!c.isActive" class="badge badge-danger">inactive</span>
                </td>
                <td>
                    <span *ngIf="c.isBlocked" class="badge badge-danger">blocked</span>
                    <span *ngIf="!c.isBlocked" class="badge badge-success">not blocked</span>
                </td>
                <td>
                    <span class="text-info pointer" title="Manage Account Vehicles" (click)="openCardVehicles(c.pan)">
                      <i class="fa fa-car-alt"></i>
                    </span>
                </td>
                <td>
                  <span class="text-info pointer" title="Card Transactions" (click)="openTransactions(c.pan, c.pan)">
                    <i class="fa fa-money-bill-wave"></i>
                  </span>
                    <span class="text-danger pointer ml-2" title="Remove Card" (click)="selectCard(c.pan, 'delete')">
                        <i class="fa fa-unlink"></i>
                    </span>
                    <span class="text-warning pointer ml-2" title="Remove PIN" (click)="selectCard(c.id, 'remove')">
                      <i class="fa fa-redo"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- table-responsive -->

        <div class="ht-40"></div>

        
      </div><!-- az-content-body -->
    
    </div><!-- container -->
</div><!-- az-content -->

<div class="modal fade" id="add" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">ADD CARD</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="addForm" (ngSubmit)="addNewCard(addForm)">
            <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      Card
                      <select formControlName="pan" class="form-control" required>
                          <option value="" selected disabled>choose...</option>
                          <option *ngFor="let c of availableCards;" value="{{c.pan}}">{{c.pan}}</option>
                      </select>
                    </div>
                  </div>
                  <!-- <div class="col-md-12">
                      <div class="form-group">
                        Pin Block
                        <input type="text" formControlName="pinBlock" placeholder="PIN" required class="form-control">
                      </div>
                  </div> -->
                </div>
              </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Add Card</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">REMOVE CARD</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2>You are about to remove a Card!</h2>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-primary" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-danger" type="button" (click)="removeCard()"><i class="fa fa-unlink"></i>&nbsp; Remove Card</button>
            </div>
          </div>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="removePin" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">REMOVE PIN</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="text-center">You are about to remove PIN on this Card!</h2>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-primary" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-danger" type="button" (click)="removeCardPin()"><i class="fa fa-unlink"></i>&nbsp; Remove PIN</button>
            </div>
          </div>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="details" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">ADD DETAILS TO CARD</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="detailForm" (ngSubmit)="updateDetails(detailForm)">
            <div class="container">
                <div class="row">
                  <div class="col-md-12">
                      <div class="form-group">
                        Firstname
                        <input type="text" formControlName="firstname" placeholder="Firstname" required class="form-control">
                      </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      Lastname
                      <input type="text" formControlName="lastname" placeholder="Lastname" required class="form-control">
                    </div>
                  </div>
                </div>
              </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Update Details</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div> 

<div class="modal fade" id="details2" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">ADD DETAILS TO CARD</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="detailForm" (ngSubmit)="updateDetails(detailForm)">
            <div class="container">
                <div class="row">
                  <div class="col-md-12">
                      <div class="form-group">
                        Firstname
                        <input type="text" formControlName="firstname" placeholder="Firstname" required class="form-control">
                      </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      Lastname
                      <input type="text" formControlName="lastname" placeholder="Lastname" required class="form-control">
                    </div>
                  </div>
                </div>
              </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Update Details</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>
