<section class="az-body">

    <div class="az-signup-wrapper">
      <div class="az-column-signup-left">
        <div>
          <!-- <i class="typcn typcn-chart-bar-outline"></i> -->
          <figure>
            <img src="/assets/img/fudzo.png" class="w-50" alt="Fudzo Logo">
          </figure>
          <h1 class="az-logo">Fudzo Admin</h1>
          <h5>Responsive Modern Dashboard &amp;</h5>
          <p>We are excited to launch our new company and product. After being featured in too many magazines to mention and having created an online stir, we know that the system is going to be big. We also hope to win Startup Fictional Business of the Year this year.</p>
          <p>Browse our site and see for yourself why you need us.</p>
          <!-- <a href="index.html" class="btn btn-outline-indigo">Learn More</a> -->
        </div>
      </div><!-- az-column-signup-left -->
      <div class="az-column-signup">
        <h1 class="az-logo">Fudzo Admin</h1>
        <div class="az-signup-header">
          <h2>Get Started</h2>
          <h4>It's free to signup and only takes a minute.</h4>

          <form [formGroup]="addForm" (ngSubmit)="createAccount(addForm)">
            <div class="form-group">
              <label>Firstname</label>
              <input formControlName="firstName" required type="text" class="form-control" placeholder="Enter your firstname">
            </div><!-- form-group -->
            <div class="form-group">
              <label>Lastname</label>
              <input formControlName="lastName" required type="text" class="form-control" placeholder="Enter your lastname">
            </div><!-- form-group -->
            <div class="form-group">
              <label>Email</label>
              <input formControlName="emailAddress" required type="text" class="form-control" placeholder="Enter your email">
            </div><!-- form-group -->
            <div class="form-group">
              <label>Mobile</label> &nbsp;&nbsp; <em class="text-muted font-smaller">+263xxxxxxxxx</em>
              <input formControlName="mobileNumber" required type="text" class="form-control" placeholder="Enter your mobile">
            </div><!-- form-group -->
            <div class="form-group">
              <label>Username</label>
              <input formControlName="userName" required type="text" class="form-control" placeholder="Enter your username">
            </div><!-- form-group -->
            <div class="form-group">
              <label>Role</label>
              <select formControlName="roleId" required class="form-control">
                <option value="" selected disabled>choose role...</option>
                <option *ngFor="let r of roles;" value="{{r.id}}">{{removeUnderscore(r.name)}}</option>
              </select>
            </div><!-- form-group -->
            <div class="form-group">
              <label>Password</label>
              <input formControlName="password" required type="password" class="form-control" placeholder="Enter your password">
            </div><!-- form-group -->
            <button type="submit" class="btn btn-az-primary btn-block">Create Account</button>
            <div class="row row-xs">
              <!-- <div class="col-sm-6"><button class="btn btn-block"><i class="fab fa-facebook-f"></i> Signup with Facebook</button></div>
              <div class="col-sm-6 mg-t-10 mg-sm-t-0"><button class="btn btn-primary btn-block"><i class="fab fa-twitter"></i> Signup with Twitter</button></div> -->
            </div><!-- row -->
          </form>
        </div><!-- az-signup-header -->
        <div class="az-signup-footer">
          <p>Already have an account? <a routerLink="/login">Sign In</a></p>
        </div><!-- az-signin-footer -->
      </div><!-- az-column-signup -->
    </div><!-- az-signup-wrapper -->

  </section>