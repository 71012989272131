  <style>
    #myicon {
      font-size: 80px;
      margin-bottom: 50px;
    }
  </style>

  <section class="az-body container bg-white">

    <div class="az-signup-wrapper row">
      <div class="col-md-6">
        <div class="mt-md-5 pt-md-5 mt-0 pt-0 d-none d-md-block">
          <figure>
            <img src="/assets/img/fudzo.png" class="w-50" alt="Fudzo Logo">
          </figure>
          <h5>Account Self Registration</h5>
        </div>
        <div class="mt-3 mt-md-0">
            <div class="form-group">
                <label>Account Type</label>
                <select class="form-control" (change)="getSelected()" id="accType" style="width: 250px;">
                    <option value="" disabled selected>choose...</option>
                    <option value="individual">Individual</option>
                    <option value="company">Company</option>
                </select>
            </div>
        </div>
        <hr class="d-md-none">
      </div><!-- az-column-signup-left -->
      <!-- Individual Account -->
      <div class="col-md-6 mt-4 mb-3" *ngIf="selected == 'individual'">
        <app-self-individual></app-self-individual>
      </div>

      <!-- Company Account -->
      <div class="col-md-6 mt-4 mb-3" *ngIf="selected == 'company'">
        <app-self-company></app-self-company>
      </div>
    </div><!-- az-signup-wrapper -->

  </section>