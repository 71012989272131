import { UsersService } from './../../../shared/services/users.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-mng-roles',
  templateUrl: './mng-roles.component.html',
  styleUrls: ['./mng-roles.component.css']
})
export class MngRolesComponent implements OnInit {

  roles: any;
  roleId: number;

  addForm: FormGroup;

  constructor(
    private toast : ToastrService,
    private userService : UsersService,
    private fb : FormBuilder
  ) { }

  ngOnInit(): void {

    this.addForm = this.fb.group({
      name: ['', Validators.required],
    });

    this.getRoles();
  }

  getRoles(){
    this.userService.getAllRoles().subscribe(
      data => {
        // console.table(data);

        this.roles = data;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permission to accees this Resource");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  createRole(form: FormGroup){
    // console.log(form.value);

    if(form.valid){
      // this.userService.createRole(form.value).subscribe(
      //   resp => {
      //     // console.log(resp);

      //     $('#add').modal('hide');
      //     this.getRoles();
      //     this.toast.success('Role Created Succesfully ');
      //     this.addForm.reset;
      //   },
      //   err => {
      //     console.error(err);
      //     if(err.status == 0){
      //       this.toast.error('Network Connection Failure');
      //     }
      //      else if(err.error?.message){
      //       this.toast.error(err.error.message);
      //     }else if(err.status == 500){
      //       this.toast.error('Internal Server Error')
      //     }
      //     else{
      //       this.toast.error('Request Failed: ' + err.status);
      //     }
      //   }
      // );
    }
    else{
      this.toast.warning("Missing Required Info");
    }
    
  }

  selectRole(ref){
    ref ? this.roleId = ref : false; $('#delete').modal('show');
  }

  deleteRole(){
    if(this.roleId){
      // this.userService.deleteRole(this.roleId).subscribe(
      //   resp => {
      //     // console.log(resp);
      //     $('#delete').modal('hide');
      //     this.getRoles();
      //     this.toast.success('Role Deleted Succesfully ');
      //   },
      //   err => {
      //     console.error(err);
      //     if(err.status == 0){
      //       this.toast.error('Network Connection Failure');
      //     }
      //      else if(err.error?.message){
      //       this.toast.error(err.error.message);
      //     }else if(err.status == 500){
      //       this.toast.error('Internal Server Error')
      //     }
      //     else{
      //       this.toast.error('Request Failed: ' + err.status);
      //     }
      //   }
      // );
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

  public removeUnderscore(str: string) {
    return str.replace(/_/g, " ");
  }

}
