<div class="az-signup-header">
    <h2>Individual Account</h2>
    <h4>It's free to signup and only takes a minute.</h4>

    <form [formGroup]="addForm" (ngSubmit)="selfRegister(addForm)">
      <div class="form-group">
        <label>Firstname</label>
        <input formControlName="firstname" required type="text" class="form-control" placeholder="Enter your firstname">
      </div><!-- form-group -->
      <div class="form-group">
        <label>Lastname</label>
        <input formControlName="lastname" required type="text" class="form-control" placeholder="Enter your lastname">
      </div><!-- form-group -->
      <div class="form-group">
        <label>Email</label>
        <input formControlName="email" required type="text" class="form-control" placeholder="Enter your email">
      </div><!-- form-group -->
      <div class="form-group">
        <label>Mobile</label> &nbsp;&nbsp; <em class="text-muted font-smaller">263xxxxxxxxx</em> &nbsp;&nbsp; <em class="text-danger font-smaller" *ngIf="mobErr">{{mobErr}}</em>
        <input formControlName="mobile" required type="text" class="form-control" placeholder="Enter your mobile">
      </div><!-- form-group -->
      <div class="form-group">
        <label>ID Number</label> &nbsp;&nbsp; <em class="text-muted font-smaller">00-0000000X00</em>
        <input formControlName="idNumber" required type="text" class="form-control" placeholder="Enter your identity number">
      </div><!-- form-group -->
      <!-- <div class="form-group">
        <label>Organisation</label>
        <select formControlName="organisationId" required class="form-control">
          <option value="" selected disabled>choose...</option>
          <option value="1">Fudzo</option>
          <option value="2">Thuli</option>
        </select>
      </div> -->
      <div class="form-group mt-4 mb-4">
        <label class="ckbox">
          <input type="checkbox" id="agree" (change)="aggreeToTerms()"><span>I agree to the <a class="pointer text-primary" data-target="#terms" data-toggle="modal">Terms & Conditions</a>.</span>
        </label>
      </div><!-- form-group -->
      <button type="submit" [disabled]="agreed" class="btn btn-az-primary btn-block">Create Account</button>
      <div class="row row-xs"></div><!-- row -->
    </form>
  </div><!-- az-signup-header -->
  <div class="az-signup-footer">
    <p>Already have an account? <a routerLink="/login">Sign In</a></p>
  </div>

  <div class="modal fade" id="terms" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center w-100" id="">Terms & Conditions</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <section>
            <div>
              <div class="container">
                <div class="row">
                  <div class="col-md-12" style="height: 65vh;">
                    <object
                      data="/assets/lib/terms/Terms_and_Conditions.pdf"
                      type="application/pdf"
                      width="100%"
                      height="100%">
                      <p>Your browser does not support document preview.
                        <a href="/assets/lib/terms/Terms_and_Conditions.pdf" target="_blank">Download the Document</a>
                      </p>
                    </object>
                  </div>
                </div>
              </div>
              <br><br>
            </div>
          </section>
        </div>  
        <div class="modal-footer" align="right">
          <button class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
        </div>    
      </div>
    </div>
  </div>