import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from './../../../shared/services/storage.service';
import { AccountsService } from './../../../shared/services/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-mng-acc-cards',
  templateUrl: './mng-acc-cards.component.html',
  styleUrls: ['./mng-acc-cards.component.css']
})
export class MngAccCardsComponent implements OnInit {

  accountNumber: any;
  availableCards: any;

  myCards: any;
  newCards: any;

  selectedCard: number;

  addForm: FormGroup;
  detailForm: FormGroup;

  mode: string;
  cardPan: string;

  constructor(
    private toast: ToastrService,
    private accountService: AccountsService,
    private storageService: StorageService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.mode = this.storageService.decrypt(sessionStorage.getItem('mode'));

    this.activeRoute.params.subscribe((params) => {
      this.accountNumber = params["id"];
    });

    this.getAccountCards();

    this.addForm = this.fb.group({
      accountNumber: [this.accountNumber, Validators.required],
      pan: ['', Validators.required],
      // pinBlock: ['', Validators.required]
    });

    this.getAvailableCards();

    this.detailForm = this.fb.group({
      pan: "",
      firstname: "",
      lastname: ""
    });
  }

  getAccountCards(){
    if(this.mode == 'company'){
      this.accountService.getCompanyAccCards(this.accountNumber).subscribe(
        data => {
          // console.table(data);
  
          this.myCards = data;
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      )
    }
    else if(this.mode == 'individual'){
      this.accountService.getIndividualAccCards(this.accountNumber).subscribe(
        data => {
          // console.table(data);
  
          this.myCards = data;
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      )
    }
  }

  getAvailableCards(){
    this.accountService.getAvailableCards('FUDZO', 0, 5000).subscribe(
      data => {
        // console.table(data);

        this.availableCards = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permission to accees this Resource");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  updateDetails(form: FormGroup){
    // console.log(form.value, this.cardPan);
    form.patchValue({pan: this.cardPan});

    if(form.valid){
      this.accountService.addCardOwner(form.value).subscribe(
        resp => {
          // console.log(resp);
          this.toast.success('Action Successfull');
          $('#details').modal('hide');
          $('#details2').modal('hide');
          this.getAccountCards();
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
          else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing required info');
    }

  }

  openCardVehicles(ref){
    if(ref){
      this.router.navigate(['/admin/accounts/cars/' + ref]);
    }
  }

  addNewCard(form: FormGroup){
    // console.log(form.value);
    if(form.valid){
      this.mode == 'individual' ?
      this.accountService.addIndividualCard(form.value).subscribe(
        resp => {
          console.log(resp);
          $('#add').modal('hide');
          this.getAccountCards();
          this.toast.success('Card Added Succesfully ');
          this.addForm = this.fb.group({
            pan: ['', Validators.required],
            accountNumber: [this.accountNumber, Validators.required],
            // pinBlock: ['', Validators.required],
          });
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      ) :
      this.accountService.addCompanyCard(form.value).subscribe(
        resp => {
          console.log(resp);
          $('#add').modal('hide');
          this.getAccountCards();
          this.toast.success('Card Added Succesfully ');
          this.addForm = this.fb.group({
            pan: ['', Validators.required],
            accountNumber: [this.accountNumber, Validators.required],
            // pinBlock: ['', Validators.required],
          });
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Fields.');
    }
    
  }

  removeCard(){
    // console.log(this.selectedCard);

    if(!this.selectedCard){
      this.toast.warning('Card reference not set.');
       return false;
    }

    let data = {
      "accountNumber": this.accountNumber,
      "pan": this.selectedCard,
      "pin": "stri"
    };

    this.mode == 'individual' ?
    this.accountService.removeIndividualCard(data).subscribe(
      resp => {
        // console.log(resp);
        this.toast.success('Card Removed.');
        this.getAccountCards();        
        $('#delete').modal('hide');
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    ) :
    this.accountService.removeCompanyCard(data).subscribe(
      resp => {
        // console.log(resp);
        this.toast.success('Card Removed.');
        this.getAccountCards();
        $('#delete').modal('hide');
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  removeCardPin(){
    console.log(this.selectedCard);

    if(!this.selectedCard){
      this.toast.warning('Card reference not set.');
       return false;
    }

    this.accountService.removeCardPin(this.selectedCard).subscribe(
      resp => {
        // console.log(resp);
        this.toast.success('Card PIN Removed.');
        this.getAccountCards();        
        $('#removePin').modal('hide');
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    ) 
  }

  selectCard(ref, typ){
    if(ref){
      this.selectedCard = ref;

      typ == 'delete' ? $('#delete').modal('show') : $('#removePin').modal('show');
    }
    else{
      this.toast.warning('No Card Reference found.');
    }
  }

  openTransactions(ref, name){
    if(ref){
      sessionStorage.setItem('trans-mode', this.storageService.encrypt('card'));
      sessionStorage.setItem('trans-disp', this.storageService.encrypt(name));

      this.router.navigate(['/admin/accounts/transactions/' + ref]);
    }
  }

  selectCard2(ref){
    if(ref){
      this.cardPan = ref;
      $('#details').modal('show');
    }
  }

  selectCard3(ref){
    if(ref){
      this.cardPan = ref;
      let dtCard;
      this.myCards.forEach(r => {
        if(r.pan == ref) dtCard = r;
      });
      // console.log(dtCard);
      this.detailForm = this.fb.group({
        pan: ref,
        firstname: dtCard.firstname,
        lastname: dtCard.lastname
      });      
      $('#details2').modal('show');
    }
  }

}
