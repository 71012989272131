import { ActivatedRoute } from '@angular/router';
import { AccountsService } from './../../../shared/services/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-mng-card',
  templateUrl: './mng-card.component.html',
  styleUrls: ['./mng-card.component.css']
})
export class MngCardComponent implements OnInit {

  cards: any;
  selectedCard: any;
  cardId: number;

  addForm: FormGroup;
  editForm: FormGroup;

  config: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  constructor(
    private fb : FormBuilder,
    private toast : ToastrService,
    private accountsService : AccountsService,
    private activateRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.getCards();
  }

  handlePageChange(event) {
    this.page = event;
    this.getCards();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getCards();
  }

  getCards(){
    this.accountsService.getPaginatedCards(this.page - 1, this.pageSize).subscribe(
      data => {
        // console.table(data);

        this.config = {
          itemsPerPage: this.pageSize,
          currentPage: data.pageable.pageNumber + 1,
          totalItems: data.totalElements,
          totalPages: data.totalPages
        };   

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }

        this.cards = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permission to accees this Resource");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }


  selectCard(ref){
    ref ? this.cardId = ref : false; $('#delete').modal('show');
  }

  deleteCard(){
    if(this.cardId){
      this.accountsService.deleteCard(this.cardId).subscribe(
        resp => {
          console.log(resp);
          $('#delete').modal('hide');
          this.getCards();
          this.toast.success('Card Deleted Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

}
